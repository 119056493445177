const expiration = new Date(2023, 10, 18, 0, 48)

const secondsNext2 = document.querySelector("[data-time__next--sec2]")
const secondsNext1 = document.querySelector("[data-time__next--sec1]")
const secondsCurrent2 = document.querySelector("[data-time__current--sec2]")
const secondsCurrent1 = document.querySelector("[data-time__current--sec1]")

const minutesNext2 = document.querySelector("[data-time__next--min2]")
const minutesNext1 = document.querySelector("[data-time__next--min1]")
const minutesCurrent2 = document.querySelector("[data-time__current--min2]")
const minutesCurrent1 = document.querySelector("[data-time__current--min1]")

const hoursNext2 = document.querySelector("[data-time__next--hour2]")
const hoursNext1 = document.querySelector("[data-time__next--hour1]")
const hoursCurrent2 = document.querySelector("[data-time__current--hour2]")
const hoursCurrent1 = document.querySelector("[data-time__current--hour1]")

const daysNext2 = document.querySelector("[data-time__next--day2]")
const daysNext1 = document.querySelector("[data-time__next--day1]")
const daysCurrent2 = document.querySelector("[data-time__current--day2]")
const daysCurrent1 = document.querySelector("[data-time__current--day1]")


let intervalID

let days
let hours
let minutes
let seconds

export default function setupCounter() {
    // for the first time
    setDistance()
    firstRenderCounter()

    intervalID = setInterval(countDown, 1000)
}

function countDown() {
    const timeBetween = setDistance()
    renderCounter()
    if (timeBetween <=0) clearInterval(intervalID)
}

function setDistance() {
    const timeBetween = (expiration - new Date()) / 1000
    if (timeBetween <= 0) {
        days = 0
        hours = 0
        minutes = 0
        seconds = 0
        return timeBetween
    }
    days = Math.floor(timeBetween / 86400)
    hours = Math.floor(timeBetween / 3600) % 24
    minutes = Math.floor(timeBetween / 60) % 60
    seconds = Math.floor(timeBetween % 60)
}

function firstRenderCounter() {
    renderFirst(secondsCurrent1, secondsCurrent2, seconds)
    renderFirst(minutesCurrent1, minutesCurrent2, minutes)
    renderFirst(hoursCurrent1, hoursCurrent2, hours)
    renderFirst(daysCurrent1, daysCurrent2, days)
}

function renderFirst(current1, current2, time) {
    current2.textContent = zeroForOneDigit(time).slice(-1)
    current1.textContent = zeroForOneDigit(time).slice(0, 1)
}


function renderCounter() {
    render(secondsCurrent1, secondsCurrent2, secondsNext1, secondsNext2, seconds)
    render(minutesCurrent1, minutesCurrent2, minutesNext1, minutesNext2, minutes)
    render(hoursCurrent1, hoursCurrent2, hoursNext1, hoursNext2, hours)
    render(daysCurrent1, daysCurrent2, daysNext1, daysNext2, days)
}

function render(current1, current2, next1, next2, time ) {
    const nextTime2 = zeroForOneDigit(time).slice(-1)
    const nextTime1 = zeroForOneDigit(time).slice(0, 1)

    next2.textContent = nextTime2
    if (next2.textContent !== current2.textContent) {
        current2.classList.add("time__current--anim")
        next2.classList.add("time__next--anim")
        next2.addEventListener("transitionend", () => {
            current2.textContent = nextTime2
            current2.classList.remove("time__current--anim")
            next2.classList.remove("time__next--anim")
        })
    }


    next1.textContent = nextTime1
    if (next1.textContent !== current1.textContent) {
        current1.classList.add("time__current--anim")
        next1.classList.add("time__next--anim")
        next1.addEventListener("transitionend", () => {
            current1.textContent = nextTime1
            current1.classList.remove("time__current--anim")
            next1.classList.remove("time__next--anim")
        })
    }

}


function zeroForOneDigit(number) {
    return ("0" + number).slice(-2)
}
