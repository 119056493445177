import setupCounter from "./countDown.js";
import {setupTable} from "./table.js";
import "./tabs.js"

const loading = document.querySelector(".loading-page")
const HASHED_PASS = "912df38fe384db9fe00ce18570a569561a76a48acfaaffa01a859df4a9bcb66a"

if (localStorage.getItem("password") === HASHED_PASS) {
    setupConfigs()
} else {
    setupLogin()
}

addEventListener("load", () => {
    setTimeout(() => loading.classList.add("loading-page--hide"), 4000)
})


function setupConfigs() {
    setupCounter()
    setupTable()
}

function setupLogin() {
    location.href = './login'
}
