[
  {
    "configName": "Cloud1",
    "configLink": "vless://aae73361-96af-435a-b154-355e07ae9e18@mtn.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud1",
    "configType": "vless\nws"
  },
  {
    "configName": "Cloud2",
    "configLink": "vless://aae73361-96af-435a-b154-355e07ae9e18@mtnc.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud2",
    "configType": "vless\nws"
  },
  {
    "configName": "Cloud3",
    "configLink": "vless://f811d379-517f-4a84-eb15-3ad5036c3755@mci.parhan.tk:443?vless://aae73361-96af-435a-b154-355e07ae9e18@mci.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud3",
    "configType": "vless\nws"
  },
  {
    "configName": "Cloud4",
    "configLink": "vless://aae73361-96af-435a-b154-355e07ae9e18@mcic.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud4",
    "configType": "vless\nws"
  },
  {
    "configName": "Cloud5",
    "configLink": "vless://aae73361-96af-435a-b154-355e07ae9e18@shatel.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud5",
    "configType": "vless\nws"
  },
  {
    "configName": "Cloud6",
    "configLink": "vless://aae73361-96af-435a-b154-355e07ae9e18@mkh.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud6",
    "configType": "vless\nws"
  },
  {
    "configName": "Cloud7",
    "configLink": "vless://aae73361-96af-435a-b154-355e07ae9e18@ast.pishi.gq:443?security=tls&sni=cloud.pishi.gq&allowInsecure=1&type=ws&path=/&host=cloud.pishi.gq&encryption=none#Cloud7",
    "configType": "vless\nws"
  }
]
